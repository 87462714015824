li {
  list-style: none;
  padding-left: 0px;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  border-width: 0;
  border-style: none;
  cursor: pointer;
}
button:active {
  border-style: none;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
}
input:focus {
  border-style: none;
  outline: none;
}
p {
  margin: 0px;
}
h3 {
  margin: 0px;
}
