@import "../util/style.scss";

.wrapper {
    position: relative;
  @include column(100%, auto, 0px);
    // box-shadow: $eff-shd-1;
    // overflow-x: hidden;
    &-title {
        width: 100px;
    }
    &-default {
        width: 270px;
    }
}

.type-title {
    visibility: hidden;
}

.border{
    border: 1px dashed $col-brd-g7;
}

.frame { 
    &-header{
        @include columnWithBorder(100%, auto, 30px, $col-brd-g8);
        > * {
            @include column(auto, auto, 15px);
        }
    }
    &-content {
        @include columnWithBorder(100%, auto, 40px, $col-brd-g8);
    }
    &-content-each {
        @include column(auto, auto, 25px);
    }
    &-content-each-title {
        align-items: flex-end;
    }
    &-map{
        @include box(calc(100% - 20px), 190px, 20px, $col-brd-g7, none);
        border-width: 0.5px;
        overflow: hidden;
    }
}

.input {
    background-color: transparent;
    width: 70px;
    &:focus::-webkit-input-placeholder{
        color: $col-txt-g8;
    }
    text-align: right;
    padding: 0px;
}

.title{
    @include txt-bold;
    @include txt-21;
    color: $col-txt-g2;
}

.btn {
    &-close {
        stroke: $col-brd-g8;
        stroke-width: 2px;
        stroke-opacity: 1;
        cursor: pointer;
    }
}