@import "../util/style.scss";

.wrapper {
  @include box(100%, 58px, 0px, none, $col-bgd-w);
  @include row(auto, 58px, 0px);
  border-bottom: 1px solid $col-brd-g8;
  width: 100%;
  justify-content: space-between;
  z-index: 2;
  box-shadow: $eff-shd-1;
}

.frame-left {
  @include row(auto, auto, 15px);
  margin-left: 34px;
}

.frame-right {
  @include row(auto, auto, 10px);
  margin-right: 11px;
}

.title-frame{
  position: absolute;
  width: 100%;
  @include row(auto, auto, 25px);
  justify-content: center;
  pointer-events: none;
}

.title{
  @include txt-bold();
  @include txt-21();
  color: $col-txt-g2;
}

.sub-title{
  @include txt-bold();
  @include txt-19();
  color: $col-txt-g7;
}
