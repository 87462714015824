@import "../util/style.scss";

.wrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}
