@import "../util/style.scss";

.wrapper {
  position: relative;
  background-color: $col-bgd-w;
}

.frame{
  &-page{
    width: 100%;
    height: 100vh;
    @include column(100%, auto, 0px);
    justify-content: space-between;
  }
  &-content{
    @include row(none, auto, 24px);
    box-sizing: border-box;
    height: 100%;
    padding-top: 46px;
    padding-left: 46px;
    overflow-y: auto;
    align-items: flex-start;
    &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
      &-thumb {
        height: 7%;
        background-color: $col-bgd-g8;
        border-radius: 10px;
      };
      &-track {
        background-color: $col-bgd-g9;
      };
    };
  };
  &-title{
    @include row(100px, auto, 24px);
  }
  &-content-wrap{
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
      &-thumb {
        height: 7%;
        background-color: $col-bgd-g8;
        border-radius: 10px;
      };
      &-track {
        background-color: $col-bgd-g9;
      };
    };
  }
  &-content-list{
    @include row(270px, auto, 24px);
    align-items: flex-start;
    cursor: pointer;
    > *{
      cursor: auto;
    }
    
  }
  &-content-box{
    width: 270px;
    
  }
}
