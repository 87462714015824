@import "../util/style.scss";

.wrapper {
  @include column(auto, auto, 11px);
  align-items: flex-start;
}

.frame { 
    &-column{
        @include column(auto, auto, 11px);
        align-items: flex-start;
    }
    &-row{
        @include row(auto, auto, 20px);
    }
    &-value{
        @include row(auto, auto, 0px);
    }
}

.title{
    &-default {
        > * {
            @include txt-bold;
            @include txt-11;
            color: $col-txt-g6;
        }
    }
    &-emph {
        > * {
            @include txt-bold;
            @include txt-11;
            color: $col-txt-g6;
        }
    }
}

.value {
    &-default {
        > * {
            @include txt-bold;
            @include txt-14;
            color: $col-txt-g5;
        }
    }
    &-emph {
        > * {
            @include txt-bold;
            @include txt-14;
            color: $col-txt-g3;
        }
    }
}

.unit {
    &-default {
        > * {
            @include txt-bold;
            @include txt-14;
            color: $col-txt-g5;
        }
    }
    &-emph {
        > * {
            @include txt-bold;
            @include txt-14;
            color: $col-txt-g3;
        }
    }
}