@import "../util/style.scss";

.wrapper {
  position: relative;
  background-color: $col-bgd-g95;
}

.frame{
  &-page{
    width: 100%;
    height: 100vh;
    @include column(100%, auto, 0px);
    justify-content: space-between;
  }
  &-content{
    @include row(auto, auto, 24px);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 24px;
    align-items: flex-start;
    cursor: pointer;
    > *{
      cursor: auto;
    }
    overflow: auto;
    &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
      &-thumb {
        height: 7%;
        background-color: $col-bgd-g8;
        border-radius: 10px;
      };
      &-track {
        background-color: $col-bgd-g9;
      };
    };
  };
  &-column{
    @include column(auto, auto, 24px);
  };
}

.not-draggable{
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
