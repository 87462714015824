@import "../util/style.scss";

.wrapper{
    @include column(auto, auto, 0px);
}

.btn-add {
  // position: absolute;
  width: 10px;
  height: 10px;
  fill: $col-brd-g6;
}

.btn{
    @include textBox(24px, 24px, 12px, $col-brd-g7, $col-bgd-w);
    cursor: pointer;
    &:hover{
      background-color: $col-bgd-g95;
    }
}
