@import "../util/style.scss";

.btn-backward {
  // position: absolute;
  width: 28px;
  height: 28px;
  fill: $col-brd-g25;
  &:hover {
    fill: $col-brd-g2;
  }
  &:active {
    fill: $col-brd-g25;
  }
  cursor: pointer;
}
