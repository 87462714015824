@import "../util/style.scss";

.wrapper{
  position: relative;
}

.frame {
  @include column(auto, auto, 0px);
  position: absolute;
  bottom: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.box {
  @include box(auto, auto, 5px, none, $col-txt-g3);
  @include column(auto, auto, 2px);
  padding: 8px;
  color: $col-txt-w;
  box-shadow: 0px 0px 5px 3px $col-shd-b;
  align-items: flex-start;
  z-index: 30;
}

.text{
  @include txt-light();
  @include txt-11();
  white-space: nowrap;
}

.triangle-down-svg {
  width: 9px;
  height: 4px;
  fill: $col-txt-g3;
  //   stroke: $col-bgd-1;
  //   stroke-width: 2px;
}
