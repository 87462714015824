@import "../util/style.scss";

.wrapper {
  width: 450px;
  height: calc(100vh - 60px);
}

.modal {
  position: relative;
  @include column(400px, auto, 0px);
  @include box(450px, 100%, 15px, auto, $col-bgd-w);
  box-shadow: 7px 7px 5px $col-shd-b;
  pointer-events: auto;
  overflow-x: nowrap;
  padding: 25px;
}

.btn-close {
  width: 16px;
  height: 16px;
  top: 19px;
  right: 17px;
  stroke: $col-txt-g1;
}

.box {
  @include textBox(100%, 48px, 0px, none, none);
  @include txt-bold;
  @include txt-19;
  color: $col-txt-w;
}

.frame {
  &-title {
    @include row(10%, auto, 0px);
    align-items: flex-start;
    border-bottom: solid 1px $col-brd-g8;
    padding-bottom: 12px;
    margin-bottom: 24px;
  }
  &-left {
    @include row(28px, 28px, 0px);
  }
  &-center {
    @include column(auto, auto, 10px);
    width: 80%;
  }
  &-right {
    @include row(auto, auto, 0px);
    justify-content: flex-end;
  }
  &-content {
    @include box(470px, auto, 0px, auto, none);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-btn {
  @include box(400px, 48px, 0px, auto, $col-bgd-2);
  filter: drop-shadow(0px -20px 5px rgba(255, 255, 255, 0.8));
  // box-shadow: 0px 0px 20px 20px $col-shd-w;
  }
}

.title {
  @include txt-bold;
  @include txt-19;
  color: $col-txt-g2;
}

.sub-title {
  @include txt-light;
  @include txt-11;
  color: $col-txt-g6;
}

.close {
  background-color: antiquewhite;
}
