@import "../util/style.scss";

.info-bubble {
  pointer-events: none;
}

.info-bubble {
  @include textBox(auto, auto, none, none, none);
  position: relative;
}

.info-bubble-svg {
  filter: drop-shadow(7px 7px 5px $col-shd-b);
  width: 98px;
  height: 65px;
  fill: $col-bgd-w;
  stroke: $col-brd-2;
  stroke-width: 1.5px;
}

.frame {
  @include column(auto, auto, 3px);
  position: absolute;
  top: 3px;
}

.info-bubble * {
  @include txt-light();
  @include txt-11();
  color: $col-txt-g6;
}

.info-bookmark {
  position: absolute;
  width: 24px;
  height: 24px;
}

.corner{
  top: -7.5px;
  right: -7.5px;
}

.title {
  @include txt-bold();
  @include txt-19();
  color: $col-txt-2;
}
