@import "../util/style.scss";

.wrapper {
  position: relative;
  background-color: $col-bgd-g95;
}

.frame{
  &-page{
    width: 100%;
    height: 100vh;
    @include column(100%, auto, 0px);
    justify-content: space-between;
  }
  &-content{
    @include row(auto, auto, 24px);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 24px;
    align-items: flex-start;
    cursor: pointer;
    > *{
      cursor: auto;
    }
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
      &-thumb {
        height: 7%;
        background-color: $col-bgd-g8;
        border-radius: 10px;
      };
      &-track {
        background-color: $col-bgd-g9;
      };
    };
  };
  &-column{
    @include column(100%, auto, 25px);
    height: 100%;
    > :last-child{
      height: auto;
      overflow: hidden;
      > * {
        height: 100%;
      }
    }
  };
  &-row{
    @include row(auto, auto, 10px);
    > :first-child{
      width: 100%;
    }
  }
}

.btn {
    &-close {
        width: 40px;
        stroke: $col-brd-g8;
        stroke-width: 2px;
        stroke-opacity: 1;
        cursor: pointer;
    }
}