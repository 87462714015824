@import "../util/style.scss";

.wrapper {
  position: fixed;
  @include textBox(100%, 100vh, 0px, none, none);
  width: 100%;
  height: 100vh;
  top: 0;
  opacity: 0;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
}

.backdrop-true {
  background-color: rgba(0, 0, 0, 0.25);
  pointer-events: auto;
}

.close {
  animation: fadeout 1s;
  pointer-events: none;
  opacity: 0;
}

.open{
  opacity: 1;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
