@import "../util/style.scss";

.wrapper {
  @include row(90px, 24px, 0px);
  width: 290px;
  height: 28px;
  justify-content: space-between;
  border-radius: 5px;
  &:hover{
    background-color: $col-bgd-g95;
  }
  padding: 0px 0px;
}

.type {
   &-default {
    * {
        @include txt-regular();
        @include txt-12();
        color: $col-txt-g3;
        &::placeholder{
            color: $col-txt-g3;
        }
    }
   } 
   &-total {
    * {
        @include txt-bold();
        @include txt-14();
        color: $col-txt-g2;
        &::placeholder{
            color: $col-txt-g2;
        }
    }
   } 
}

.frame { 
    @include row(auto, auto, 0px);
    height: 24px;
    width: 80px;
    padding-right: 6px;
    justify-content: flex-end;
    &-value{
        display: flex;
      flex-direction: column;
      align-items: flex-end;
    //   overflow: hidden;
      width: 90px;
    }
}

.blob {
    border-radius: 5px;
    background-color: $col-bgd-g95;
    &:focus-within{
        background-color: $col-bgd-3;
    }
}

.input {
    background-color: transparent;
    width: 50px;
    &:focus::-webkit-input-placeholder{
        color: $col-bgd-4;
    }
    text-align: right;
    padding: 0px;
}

.text {
    text-align: right;
    white-space: nowrap;
    > * {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.unit { 
    white-space: nowrap;
}

.frame-left{
    @include row(auto, auto, 0px);
    padding-left: 6px;
    height: 24px;
}

.title{
    text-align: left;
    white-space: nowrap;
}