@import "../util/style.scss";

.wrapper {
  @include box(100%, 58px, 0px, none, $col-bgd-w);
  @include row(auto, auto, 0px);
  border-bottom: 1px solid $col-brd-g8;
  width: 100%;
  justify-content: space-between;
  @include txt-stl-2();
  z-index: 2;
}

.frame {
  &-left{
    margin-left: 26px;
  }
  &-center {
    position: absolute;
    width: 100%;
    @include row(auto, auto, 15px);
    justify-content: center;
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  }
  &-right {
    @include row(auto, auto, 10px);
    margin-right: 23px;
  }
}

.navigation {
  @include row(auto, 40px, 0px);
  & > :first-child {
    border-radius: 2px 0px 0px 2px;
  }
  // & > :not(:first-child) {
  //   // border-left: 0px;
  // }
  & > :last-child {
    border-radius: 0px 2px 2px 0px;
  }
   & > * {
     & > * {
      @include textBox(auto, 40px, 0px, $col-brd-g8, $col-bgd-w);
      padding: 8px 16px;
      &:hover:not(.emph) {
        background-color: $col-bgd-g95;
      };
      &.emph {
        border-left-width: 1px;
        border-color: $col-bgd-2;
        color: $col-txt-2;
        z-index: 1;
      };
    }
  }
}

.btn-account {
  fill: $col-bgd-g25;
}
