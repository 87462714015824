@import "../util/style.scss";

.wrapper {
  position: relative;
  overflow: hidden;
}

.frame {
  &-header {
    z-index: 2;
  }
  &-page{
    width: 100%;
    height: 100vh;
    @include column(100%, auto, 0px);
  }
  &-content{
    position: relative;
    height: calc(100% - 58px);
  }
  &-modal-preset{
    z-index: 1;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
    padding: 20px;
    @include row(none, calc(100vh - 60px), 0px);
    > * {
      max-height: 100%;
    }
  }
  &-change-unit {
    z-index: 1;
    position: absolute;
    right: 11px;
    top: 9px;
  }
}
