@import "../util/style.scss";

.wrapper {
  @include textBox(auto, auto, 0px, none, none);
  height: 25px;
}

.frame { 
    @include row(auto, auto, 0px);
    *{
        @include txt-bold();
        @include txt-17();
    }
}

.title{
    @include txt-regular();
    @include txt-14();
    line-height: 17px;
    color: $col-txt-g3;
}

.text{
    color: $col-txt-g3;
}

.unit{
    color: $col-txt-g25;
}