@import "../util/style.scss";

.wrapper {
  @include columnWithBorder(400px, auto, 48px, $col-brd-g8);
  padding-bottom: 50px; 
}

.frame {
  &-column {
    @include column(100%, auto, 24px);
  }
  &-div1 {
    @include column(100%, auto, 0px);
    > * {
      overflow: nowrap;
    }
    align-items: flex-start;
  }
  &-div2 {
    @include row(50%, auto, 0px);
    > * {
      overflow: nowrap;
    }
    align-items: flex-start;
  }
  &-div4 {
    @include row(25%, auto, 0px);
    > * {
      overflow: nowrap;
    }
    align-items: flex-start;
  }
}

.toggle {
  fill: $col-brd-g5;
  
}

.toggleBox {
  @include box(100%, auto, 0px, none, none);
  cursor: pointer;
}

.dropped-down {
  transform: rotate(0.5turn);
}