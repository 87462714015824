@import "../util/style.scss";

.wrapper {
    position: relative;
    @include box(340px, 804px, 5px, none, $col-bgd-w);
  @include column(auto, auto, 0px);
    box-shadow: $eff-shd-1;
    justify-content: space-between;
    // overflow-x: hidden;
}

.white {
    background-color: white;
}

.border{
    border: 1px dashed $col-brd-g7;
}

.frame { 
    &-content{
        @include columnWithBorder(290px, auto, 18px, $col-brd-g8);
        overflow-y: scroll;
        overflow-x:visible;
        width: 110%;
        margin-top: 20px;
        &::-webkit-scrollbar {
            display: none;
        }
        &-each {
            @include column(290px, 20px, 9px);
        }  
    }
    &-footer{
        width:340px;
        @include column(285px, auto, 15px);
        bottom: 0px;
        padding: 15px 0px;
        background-color: $col-bgd-g25;
        border-radius: 0px 0px 5px 5px;
    }
}

.input {
    background-color: transparent;
    width: 70px;
    &:focus::-webkit-input-placeholder{
        color: $col-txt-g8;
    }
    text-align: right;
    padding: 0px;
}

.title{
    @include txt-regular;
    @include txt-19;
    color: $col-txt-g2;
}

.sub-title{
    @include txt-regular;
    @include txt-14;
    color: $col-txt-g5;
}

.value{
    @include txt-bold;
    @include txt-28;
    color: $col-txt-g2;
}

.unit{
    @include txt-regular;
    @include txt-17;
    color: $col-txt-g4;
}

.seperator{
    @include txt-regular;
    @include txt-21;
    color: $col-txt-g4;
}