@import "../util/style.scss";

.wrapper {
  @include column(none, none, 30px);
  position: relative;
  background-color: $col-bgd-g95;
  padding-top: 30px;
  padding-bottom: 30px;
}

.title {
  @include txt-head;
  font-size: 32px;
  margin-top: 60px;
  color: $col-bgd-g5;
}

.wrapper > .title{
   &:first-child {
    margin-top: 0px;
    color: $col-bgd-2;
  }
  &:last-child {
    margin-top: 200px;
    color: $col-bgd-2;
  }
}

.event {
  @include txt-head;
  background-color: $col-bgd-g8;
  padding: 15px;
  color: $col-txt-w;
  cursor: pointer;
  &:hover {
    background-color: $col-bgd-g9;
  }
  &:active {
    background-color: $col-bgd-g8;
  }
}

.relative {
  position: relative;
}

.background {
  width: 100%;
  @include column(auto, auto, 0px);
  &-white{
      width: 80%;
  @include column(none, none, 30px);
  padding-bottom: 30px;
    background-color: $col-bgd-w;
  }
  &-dark{
      width: 80%;
  @include column(none, none, 30px);
  padding-bottom: 30px;
    background-color: $col-bgd-g3;
  }
}


.map-wrapper {
  width: 80%;
  height: 80vh;
  overflow: hidden;
}

.modal-area{
  position: relative;
  z-index: 30;
}
