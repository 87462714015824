@import "../util/style.scss";

.wrapper {
  @include box(583px, 326px, 0px, none, $col-bgd-w);
  @include column(calc(100%), 16px, 10px);
  padding: 30px;
  justify-content: space-between;
}

.title-field {
  @include row(auto, 26px, 0px);
  justify-content: space-between;
}

.title {
  @include txt-head-h1;
}

.btn-close {
  width: 16px;
  height: 16px;
  stroke: $col-brd-b;
  stroke-width: 2px;
  opacity: 0.5;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.9;
  }
}

.input-field {
  @include row(auto, 51px, 0px);
  height: 51px;
  justify-content: space-between;
  border-bottom: 1.5px solid $col-brd-g4;
}

.input {
  @include textBox(auto, auto, 0px, none, $col-bgd-w);
  @include txt-head;
  font-size: 33px;
  align-items: center;
  text-align: left;
  z-index: 0;
  &::placeholder {
    color: $col-txt-g8;
  }
}

.btn-field {
  @include row(auto, auto, 0px);
  height: 35px;
  justify-content: space-between;
  * {
    background: none;
    @include txt-head-h15;
    color: $col-bgd-g5;
  }
}

.btn-list-save {
  @include row(auto, 35px, 27px);
}

.emph {
  @include textBox(auto, auto, 0px, none, $col-bgd-2);
  padding: 10px 20px;
  color: $col-txt-w;
}
